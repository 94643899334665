$(function() {
  AOS.init({
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
  });
});

$(document).ready(function(){
    $('.slider-for').slick({
      slidesToScroll: 2,
      infinite: false,
      variableWidth: true,
      prevArrow: '<button class="PrevArrow d-flex align-items-center justify-content-center"><i class="fas fa-arrow-left"></i></button>',
      nextArrow: '<button class="NextArrow d-flex align-items-center justify-content-center"><i class="fas fa-arrow-right"></i></button>',
    });
    $(".Modern-Slider").slick({
        autoplay: true,
        autoplaySpeed: 60000,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        dots: false,
        pauseOnDotsHover: false,
        cssEase: 'ease-in-out',
        fade: true,
        draggable: false,
        prevArrow: '<button class="PrevArrow d-flex align-items-center justify-content-center"><i class="fas fa-arrow-left"></i></button>',
        nextArrow: '<button class="NextArrow d-flex align-items-center justify-content-center"><i class="fas fa-arrow-right"></i></button>', 
    });

    // Shrink header on scroll
    function resizeHeaderOnScroll() {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 275,
      headerEl = $('.navbar-brand');
      if (distanceY > shrinkOn) {
        $(headerEl).addClass('smaller');
      } else {
        $(headerEl).removeClass('smaller');
      }
    }
    window.addEventListener('scroll', resizeHeaderOnScroll);

    // Side nav
    $('.page_item_has_children > a').after('<div class="btn-dropdown"><i class="fas fa-angle-right"></i></div>')
    $('.current_page_ancestor').children('.btn-dropdown').addClass('active')
    $('.current_page_item').children('.btn-dropdown').addClass('active')
    $(".btn-dropdown").on('click', function(e) {
        e.preventDefault()
        $(this).toggleClass('active')
        $(this).siblings('.children').slideToggle(200);
    });

});
