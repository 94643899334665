import 'jquery'
import 'bootstrap'
// import 'bootstrap/scss/bootstrap.scss'
import 'popper.js'
import '@fortawesome/fontawesome-free/js/all'

import AOS from 'aos'
global['AOS'] = AOS
import 'aos/dist/aos.css'

import slick from 'slick-carousel'
global['slick'] = slick
import 'slick-carousel/slick/slick.css'

import 'animate.css'

import '../sass/style.scss'

import Lightbox from 'lightbox2'
global['Lightbox'] = Lightbox
import 'lightbox2/dist/css/lightbox.min.css'

import '../js/custom'
